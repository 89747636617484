<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <fragment v-if="!isLoading">
    <v-row>
      <v-col cols="12">
        <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('btn.edit')}`">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  color="error"
                  class="mb-4 me-3"
                  @click="$router.push({ name: 'hotels-contrate-list' })"
                >
                  <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                  <span>{{ $t('btn.back') }}</span>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  v-if="!$store.state.app.onlyShow"
                  color="primary"
                  class="mb-4 me-3"
                  :loading="loading"
                  :disabled="verifyContrate"
                  @click="save()"
                >
                  <span>{{ btnTitle }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-if="model.proveedor_id"
                cols="12"
                md="4"
              >
                <p class="mt-3">
                  <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.identificador"
                  :label="$t('lbl.identificador')"
                  class="pt-2"
                  outlined
                  dense
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                ></v-text-field>
              </v-col>
              <!--<v-col
                cols="12"
                md="2"
              >
                <v-switch
                  v-model="model.stopSale"
                  :label="$t('lbl.stopSale')"
                  hide-details
                  class="mt-2"
                  :disabled="$store.state.app.onlyShow"
                ></v-switch>
              </v-col>-->
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="model.currency_id"
                  :items="currencies"
                  :label="$t('register.moneda')"
                  outlined
                  dense
                  item-text="code"
                  item-value="id"
                  class="pt-2"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-switch
                  v-model="model.cancelado"
                  :label="$t('lbl.canceled')"
                  hide-details
                  class="mt-2"
                  :disabled="$store.state.app.onlyShow"
                  @click="setCanceled"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-switch
                  v-model="editObservations"
                  :label="$t('lbl.observations')"
                  hide-details
                  class="mt-2 mb-2"
                  :disabled="$store.state.app.onlyShow"
                ></v-switch>
              </v-col>
              <v-col
                v-if="editObservations"
                cols="12"
                md="4"
              >
                <v-tooltip
                  top
                  class="text-right"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="addObservationContrateHotel()"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.insert') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="editObservations"
                cols="12"
                md="12"
              >
                <Observation
                  v-for="(observation, indO) in observationsHotel"
                  :key="indO"
                  :observation="observation"
                  :index="indO"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </app-card-code>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="search.country"
            :items="countries"
            :label="$t('lbl.country')"
            outlined
            dense
            hide-details
            @change="changeCountry"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.country') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="search.state"
            :items="states"
            :label="$t('lbl.provinceEstado')"
            outlined
            dense
            hide-details
            @change="changeState"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.provinceEstado') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="search.city"
            :items="cities"
            :label="$t('lbl.locality')"
            outlined
            dense
            hide-details
            @change="changeCity"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.locality') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="search.operador_id"
            :items="itemsOperador"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setOperadores"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="search.marca_asociada_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarcas"
            hide-details
            hide-selected
            :label="$t('menu.marcaAsociada')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setMarca"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marcaAsociada') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="search.hotel_id"
            :items="itemsHotel"
            :search-input.sync="searchHotel"
            hide-details
            hide-selected
            :label="$t('menu.hotels')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setHotel"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.hotels') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          v-if="
            search.country ||
              search.state ||
              search.city ||
              search.operador_id ||
              search.marca_asociada_id ||
              search.hotel_id
          "
          class="mx-2"
          fab
          outlined
          small
          color="error"
          @click="clearSearch"
        >
          <v-icon small>
            {{ icons.mdiEraserVariant }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="text-left"
      >
        <v-tooltip
          v-if="!$store.state.app.onlyShow"
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addHotels()"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('lbl.asociarHotels') }}</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        {{ hotels.length }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.hotels').toLowerCase() }}
      </v-col>
    </v-row>
    <v-divider class="mb-4 mt-4"></v-divider>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-uppercase"
                  width="40%"
                >
                  {{ $t('lbl.name') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.destino') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('menu.typeDestination') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('menu.operador') }}
                </th>
                <th
                  v-if="permisos.includes('hotel:edit') || permisos.includes('hotel:list')"
                  width="150px"
                  class="text-uppercase"
                >
                  {{ $t('lbl.actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <fragment
                v-for="(iten, indP) in hotelsPagination"
                :key="indP"
              >
                <tr v-if="iten.id === iten.id">
                  <td>
                    <NameHotelStar :hotel="iten" />
                  </td>
                  <td>
                    {{ `${iten.city ? `${iten.city}, ` : ''}` }}
                    <span v-if="iten.country">{{ iten.country }}</span>
                  </td>
                  <td>
                    <span v-if="iten.tipo_destino_id">
                      {{ iten.tipo_destino.name }}
                    </span>
                  </td>
                  <td>
                    <span v-if="iten.operador_id">
                      {{ iten.operador.name }}
                    </span>
                    <!--<span v-if="iten.marca_asociada_id">
                      <br />
                      {{ iten.marca_asociada.name }}
                    </span>-->
                  </td>
                  <td
                    v-if="
                      permisos.includes('contrate_hotels:edit') ||
                        permisos.includes('contrate_hotels:list') ||
                        permisos.includes('contrate_hotels:destroy')
                    "
                  >
                    <v-tooltip
                      v-if="permisos.includes('contrate_hotels:edit') && !$store.state.app.onlyShow"
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="editItem(iten)"
                        >
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="permisos.includes('contrate_hotels:list') || permisos.includes('*')"
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="showItem(iten)"
                        >
                          <v-icon>{{ icons.mdiEye }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.see') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="permisos.includes('contrate_hotels:destroy') && !$store.state.app.onlyShow"
                      top
                      color="error"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteItem(iten)"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </fragment>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text class="pt-2">
          <v-row>
            <v-col
              lg="12"
              cols="12"
              class="d-flex justify-end"
            >
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                total-visible="5"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>

      <!-- MOSTRAR DELETE CONTRATE-->
      <v-dialog
        v-model="isDialogVisibleDelete"
        scrollable
        max-width="380px"
      >
        <v-card max-height="400px">
          <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>{{ $t('msg.deleteItem', { item: hotelDelete.name }) }}</v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              outlined
              @click="isDialogVisibleDelete = !isDialogVisibleDelete"
            >
              {{ $t('btn.close') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              :loading="loading"
              @click="confirmDelete()"
            >
              {{ $t('btn.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiCheckUnderline,
  mdiMinus,
  mdiEye,
  mdiEraserVariant,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import NameHotelStar from '@/views/products/hotels/utils/NameHotelStar.vue'
// eslint-disable-next-line import/no-unresolved
import Observation from '@/views/contratos/hotels/utils/Observation.vue'

export default {
  components: {
    AppCardCode,
    NameHotelStar,
    Observation,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      isLoadingPrices: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiCheckUnderline,
        mdiMinus,
        mdiEye,
        mdiEraserVariant,
      },
      model: {
        hotels_pactados: [],
      },
      hotelDelete: {
        id: null,
        name: null,
      },
      loading: false,
      loadingSeason: true,
      isUpdatePrices: false,
      pagination: {
        current: 1,
        total: 0,
      },

      hotels: [],
      search: {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
      },
      countries: [],
      states: [],
      cities: [],
      operadores: [],
      itemsOperador: [],
      searchOperador: null,
      itemsMarcas: [],
      allMarcas: [],
      searchMarcas: null,
      itemsHotel: [],
      allHotel: [],
      searchHotel: null,
      totalItems: 0,
      totalF: 0,

      itemsPerPage: 10,
      hotelsNomAll: [],
      roomsNomAll: [],
      regimenNomAll: [],
      itemsRooms: [],
      searchRooms: null,
      seasonsContrate: [],
      hotel_pactado: null,
      room_hotel: null,
      season_hotel: [],
      rooms_hotel: [],
      regimen_hotel: 0,
      cantPaxs: 3,
      currencies: [],
      isDialogVisibleDelete: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      editObservations: false,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      temporadasContrateHotel: state => state.app.temporadasContrateHotel,
      hotelsAsociadosTemporadas: state => state.app.hotelsAsociadosTemporadas,
      idsHotelsAsociadosTemporadas: state => state.app.idsHotelsAsociadosTemporadas,
      pricesHotelContrate: state => state.app.pricesHotelContrate,
      onlyShow: state => state.app.onlyShow,
      observationsHotel: state => state.app.observationsHotel,
    }),
    hotelesAll() {
      const hPactados = []
      this.hotelsNomAll.forEach(hote => {
        // this.idsHotelsAsociadosTemporadas.forEach(hAsoc => {
        this.model.hotels_pactados.forEach(hAsoc => {
          if (hote.id === hAsoc) {
            hPactados.push(hote)
          }
        })
      })

      return hPactados
    },
    hotelsPagination() {
      const arr = []
      let index = 0
      if (this.pagination.current > 1) {
        index = parseInt(this.itemsPerPage * (this.pagination.current - 1), 10)
      }
      const cant = parseInt(index + this.itemsPerPage, 10)
      // eslint-disable-next-line no-plusplus
      for (index; index < cant && index < this.hotels.length; index++) {
        arr.push(this.hotels[index])
      }

      return arr
    },
    verifyContrate() {
      let disabled = true
      const jsonVerify = sessionStorage.getItem('verify-contrate')
      const jsonContrate = JSON.stringify({
        identificador: this.model.identificador,
        cancelado: !!this.model.cancelado,
        currency_id: this.model.currency_id,
        observations: this.observationsHotel,
      })

      if (jsonVerify !== jsonContrate) {
        disabled = false
      }

      return disabled
    },
  },
  watch: {
    searchOperador(val) {
      if (val !== null && val.length > 1) {
        this.filterOperador(val.toLowerCase())
      }
    },
    searchMarcas(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      }
    },
    searchHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getHoteles()
    this.getOperadores()
    this.getCurrencies()
    if (sessionStorage.getItem('contrate-hotels-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
  },

  methods: {
    ...mapMutations([
      'setOnlyShow',
      'updateHotelesPactadosContrateHotel',
      'addTemporadaContrateHotel',
      'updateIdsHotelsContrateHotel',
      'updateTemporadaContrateHotel',
      'updateCantPaxsContrate',
      'updatePricesHotelContrate',
      'addPricesHotelContrate',
      'updateSeasonContrateHotel',
      'updateRoomSeasonContrateHotel',
      'updateSuplementSeasonContrateHotel',
      'updateRangeAgeContrateHotel',
      'updateObservationContrateHotel',
      'addObservationContrateHotel',
    ]),
    addHotels() {
      this.$router.push({ name: 'hotels-contrate-hotels-asociar' })
    },
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.currencies = res.data.data
      })
    },
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
    },
    filterHotel() {
      this.itemsHotel = this.allHotel.filter(e => e.name.toLowerCase())
    },

    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
          }
        })
        .finally(() => {
          this.getItem()
          this.getCountries()
        })
    },
    getItem() {
      if (sessionStorage.getItem('hotels-contrate-id') !== null) {
        const id = sessionStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.hotels = this.hotelesAll
            this.allHotel = this.hotels
            this.totalItems = this.model.hotels_pactados.length

            // PARA LA PAGINACION
            this.recalculeTotalPagination()

            const jsonVerifyUpdate = {
              identificador: this.model.identificador,
              cancelado: !!this.model.cancelado,
              observations: [],
              currency_id: this.model.currency_id,
            }

            if (this.model.observations) {
              this.updateObservationContrateHotel(this.model.observations)
              jsonVerifyUpdate.observations = this.model.observations
            } else {
              this.updateObservationContrateHotel([])
            }

            sessionStorage.setItem('verify-contrate', JSON.stringify(jsonVerifyUpdate))

            // this.updateHotelesPactadosContrateHotel(this.model.hotels_pactados)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadores = res.data.data
        })
    },
    setOperadores() {
      this.hotels = []
      this.allMarcas = []
      this.allHotel = []
      this.itemsHotel = []
      this.itemsMarcas = []
      if (this.operadores.filter(e => e.id === this.search.operador_id).length > 0) {
        if (this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas.length > 0) {
          this.allMarcas = this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas
          this.allHotel = this.hotelesAll.filter(e => e.operador_id === this.search.operador_id)
          this.itemsMarcas = this.allMarcas
          this.filtersHotels()
        }
      }

      this.recalculeTotalPagination()
    },
    setMarca() {
      this.hotels = []
      this.allHotel = []
      this.itemsHotel = []
      if (this.allMarcas.filter(e => e.id === this.search.marca_asociada_id).length > 0) {
        this.allHotel = this.hotelesAll.filter(e => e.marca_asociada_id === this.search.marca_asociada_id)
        this.filtersHotels()
      }
    },
    setHotel() {
      this.hotels = []
      this.filtersHotels()
    },
    getCountries() {
      this.hotelsNomAll.forEach(hotelsNom => {
        if (!this.countries.includes(hotelsNom.country)) {
          if (hotelsNom.country) {
            this.countries.push(hotelsNom.country)
          }
        }
      })
    },
    changeCountry() {
      this.states = []
      this.search.state = null
      this.search.city = null
      if (this.search.country) {
        const states = this.hotelesAll.filter(e => e.country === this.search.country)
        states.forEach(element => {
          if (!this.states.includes(element.state) && element.state) {
            this.states.push(element.state)
          }
        })
      }
      this.filtersHotels()
    },
    changeState() {
      this.cities = []
      this.search.city = null
      if (this.search.state) {
        const states = this.hotelesAll.filter(e => e.state === this.search.state)
        states.forEach(element => {
          if (!this.cities.includes(element.city)) {
            this.cities.push(element.city)
          }
        })
      }
      this.filtersHotels()
    },
    changeCity() {
      this.filtersHotels()
    },
    filtersHotels() {
      this.pagination.current = 1
      this.hotels = []
      this.hotelesAll.forEach(hotel => {
        let introducir = true
        if (this.search.operador_id) {
          if (this.search.operador_id !== hotel.operador_id) {
            introducir = false
          }
        }
        if (this.search.marca_asociada_id) {
          if (this.search.marca_asociada_id !== hotel.marca_asociada_id) {
            introducir = false
          }
        }
        if (this.search.hotel_id) {
          if (this.search.hotel_id !== hotel.id) {
            introducir = false
          }
        }
        if (this.search.country) {
          if (this.search.country !== hotel.country) {
            introducir = false
          }
        }
        if (this.search.state) {
          if (this.search.state !== hotel.state) {
            introducir = false
          }
        }
        if (this.search.city) {
          if (this.search.city !== hotel.city) {
            introducir = false
          }
        }

        if (introducir) {
          this.hotels.push(
            hotel,

            /* {
            id: hotel.id,
            name: hotel.nameComodin,
          } */
          )
        }
      })

      this.recalculeTotalPagination()
    },
    recalculeTotalPagination() {
      // PARA LA PAGINACION
      this.pagination.total = parseInt(Math.ceil(this.hotels.length / this.itemsPerPage), 10)
    },
    clearSearch() {
      this.pagination.current = 1
      this.hotels = this.hotelesAll
      this.allHotel = this.hotels
      this.states = []
      this.cities = []
      this.search = {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
      }

      this.recalculeTotalPagination()
    },

    showItem(iten) {
      sessionStorage.setItem('hotel-contrato-show', true)
      sessionStorage.setItem('hotels-id', iten.id)

      /* this.updateSeasonContrateHotel([])
      this.updateRoomSeasonContrateHotel([])
      this.updateSuplementSeasonContrateHotel([])
      this.updateRangeAgeContrateHotel({
        adults_min_years: null,
        childs_max_years: null,
        infant_max_years: null,
      }) */

      this.$router.push({ name: 'hotels-contrate-seasons' })
    },
    editItem(iten) {
      sessionStorage.removeItem('hotel-contrato-show')
      sessionStorage.setItem('hotels-id', iten.id)
      this.$router.push({ name: 'hotels-contrate-seasons' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.hotelDelete.id = iten.id
      this.hotelDelete.name = iten.name
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .post(
          `contrate_hotels/delete-hotel/${sessionStorage.getItem('hotels-contrate-id')}`,
          { hotel_id: this.hotelDelete.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.getItem()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.hotelDelete = {
        id: null,
        name: null,
      }
      this.loading = false
    },

    save() {
      const json = {
        identificador: this.model.identificador,
        publicado: !this.model.stopSale,
        cancelado: this.model.cancelado,
        currency_id: this.model.currency_id,
        observations: this.observationsHotel,
      }
      this.loading = true

      this.axios
        .put(`contrate_hotels/${this.model.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))

            // sessionStorage.removeItem('hotels-contrate-id')
            // this.$router.push({ name: 'hotels-contrate-list' })
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getItem()
          this.loading = false
          this.editObservations = false
        })
    },
    setCanceled() {
      if (this.model.cancelado) {
        this.model.publicado = false
        this.model.stopSale = true
      }
    },
  },
}
</script>
